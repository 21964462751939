<template>
  <div class="col-12">
    <div class="layout-dashboard">
      <div class="grid">
        <div class="col-12 lg:col-6 xl:col-2">
          <div class="overview-box card">
            <span class="overview-title">{{ props.movements.length }} OT</span>
            <div class="overview-subtitle">Creadas</div>
            <div class="overview-subinfo">En el período seleccionado</div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
          <div class="overview-box card">
            <span class="overview-title">{{ dailyAverage }} Envíos</span>
            <div class="overview-subtitle">Promedio diario</div>
            <div class="overview-subinfo">En el período seleccionado</div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
          <div class="overview-box card">
            <span class="overview-title">{{ refoundedsPercentage }}%</span>
            <div class="overview-subtitle">Porcentaje devoluciones</div>
            <div class="overview-subinfo">En el período seleccionado</div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
          <div class="overview-box card">
            <span class="overview-title">{{ weightAverage }} Kg</span>
            <div class="overview-subtitle">Peso promedio</div>
            <div class="overview-subinfo">En el período seleccionado</div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
          <div class="overview-box card">
            <span class="overview-title">{{ packagesAverage }}</span>
            <div class="overview-subtitle">Bultos promedio</div>
            <div class="overview-subinfo">En el período seleccionado</div>
          </div>
        </div>
        <div class="col-12 lg:col-6 xl:col-2">
          <div class="overview-box card">
            <span class="overview-title"
              >{{ props.shipments.length }} Ventas</span
            >
            <div class="overview-subtitle">Creadas</div>
            <div class="overview-subinfo">En el período seleccionado</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { getDatesBetweenDates } from "@/services/utils";
const props = defineProps({
  shipments: Array,
  filterData: Object,
  movements: Array,
});

const dailyAverage = computed(() => {
  const days = getDatesBetweenDates(
    props.filterData.startDate,
    props.filterData.endDate
  );
  const average = props.movements.length / days.length;
  return average.toFixed(2);
});

const reFoundedsCount = computed(() => {
  const reFoundeds = props.shipments.filter(
    (shipment) => shipment.status_id === 6
  );
  return reFoundeds.length;
});

const refoundedsPercentage = computed(() => {
  const percentage = (reFoundedsCount.value * 100) / props.movements.length;
  if (isNaN(percentage)) return 0;
  return percentage.toFixed(2);
});

const packagesAverage = computed(() => {
  const packages = props.movements.map((movement) => movement.packages);
  let packagesSum = 0;
  packages.forEach((element) => {
    packagesSum += parseInt(element);
  });
  const average = packagesSum / props.movements.length;
  if (isNaN(average)) return 0;
  return average.toFixed(2);
});

const weightAverage = computed(() => {
  const weights = props.movements.map((movement) => movement.size_data.weight);
  let weightsSum = 0;
  weights.forEach((element) => {
    weightsSum += parseInt(element);
  });
  const average = weightsSum / props.movements.length;
  if (isNaN(average)) return 0;
  return average.toFixed(2);
});
</script>

<style scoped>
.overview-icon {
  color: #bbdefb;
}
.overview-subinfo {
  border-color: #bbdefb;
  color: #bbdefb;
}
.card {
  background-color: #175c94;
}
</style>
