<template>
  <div class="grid p-fluid">
    <Toast />
    <ConfirmDialog />
    <Filter
      :customerId="customer.id"
      :loadShipments="loadShipments"
      v-if="customer.id"
    />

    <ProgressSpinner
      v-if="loading"
      style="width: 50px; height: 50px; margin-top: 20px; margin-left: 40%"
      class="spinner"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />

    <Summary
      :shipments="shipments"
      :movements="movements"
      v-if="!loading"
      :filterData="filterData"
    />

    <ShipmentsChart
      :shipments="shipments"
      :movements="movements"
      :filterData="filterData"
      v-if="!loading"
    />

    <ZoneChart :movements="movements" v-if="!loading" />
  </div>
</template>
<script setup>
import Filter from "./Filter.vue";
import { ref } from "vue";
import { readUserData } from "@/services/user";
import { findByUser } from "@/api/customer";
import { useToast } from "primevue/usetoast";
import { getFilteredShipments, getMovements } from "@/api/shipment";
import ShipmentsChart from "./ShipmentsChart.vue";
import Summary from "./Summary.vue";
import ZoneChart from "./ZoneChart.vue";
import ProgressSpinner from "primevue/progressspinner";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { useRouter } from "vue-router";
import { pendingConfigMessage } from "@/constants";

const router = useRouter();
const user = readUserData();
const customer = ref({});
const loading = ref(true);
const toast = useToast();
const shipments = ref([]);
const filterData = ref({});
const movements = ref([]);
const confirm = useConfirm();

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
    if (!customer.value.id) {
      needsConfiguration();
      return;
    }
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const startComponent = async () => {
  loading.value = true;
  await getCustomerData();
};

startComponent();

const loadShipments = async (params) => {
  loading.value = true;
  filterData.value = params;
  try {
    const { data } = await getFilteredShipments(customer.value.id, params);
    shipments.value = data;
    loadMovements(params);
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const loadMovements = async (params) => {
  try {
    params.statusId = 3;
    const { data } = await getMovements(customer.value.id, params);
    movements.value = data;
    loading.value = false;
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const needsConfiguration = () => {
  confirm.require({
    message: pendingConfigMessage,
    header: "Configuración pendiente",
    icon: "pi pi-info-circle",
    acceptLabel: "Ok",
    accept: () => {
      router.push("/config");
    },
  });
};
</script>
