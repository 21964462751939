<template>
  <div class="col-12">
    <Accordion :activeIndex="0">
      <AccordionTab header="Filtrar Datos">
        <div class="formgroup-inline" id="filter">
          <span class="p-float-label field">
            <Calendar
              v-model="startDate"
              dateFormat="dd/mm/yy"
              class="p-inputtext-lg md:w-15rem"
            />
            <label for="company-rut">Inicio</label>
          </span>

          <span class="p-float-label field">
            <Calendar
              v-model="endDate"
              dateFormat="dd/mm/yy"
              class="p-inputtext-lg md:w-15rem"
            />
            <label for="company-web">Término</label>
          </span>

          <span class="p-float-label field">
            <Dropdown
              v-model="selectedCourier"
              :options="couriers"
              optionLabel="name"
              class="p-inputtext-lg w-full md:w-15rem"
            />
            <label for="company-web">Courier</label>
          </span>

          <span class="p-float-label field">
            <Dropdown
              v-model="selectedStatus"
              :options="status"
              optionLabel="description"
              class="p-inputtext-lg w-full md:w-15rem"
            />
            <label for="company-web">Estado</label>
          </span>

          <span class="p-float-label field">
            <Button
              label="Buscar"
              icon="pi pi-search"
              @click="search"
              class="p-inputtext-lg w-full md:h-3rem"
            />
          </span>
        </div>
      </AccordionTab>
    </Accordion>
  </div>
</template>
<script setup>
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { getCouriers } from "@/api/courier";
import { ref, defineProps } from "vue";
import { getStatus } from "@/api/status";
import { substractMonth } from "@/services/utils";

const startDate = ref(substractMonth());
const endDate = ref(new Date());

const props = defineProps({
  customerId: String,
  loadShipments: Function,
});
const couriers = ref([{ name: "Todos", id: 0 }]);
const selectedCourier = ref(couriers.value[0]);
const status = ref([{ description: "Todos", id: 0 }]);
const selectedStatus = ref(status.value[0]);

const loadCouriers = async () => {
  try {
    const { data } = await getCouriers(props.customerId);
    couriers.value = [...couriers.value, ...data];
  } catch (err) {
    console.log(err);
  }
};

const loadStatus = async () => {
  const { data } = await getStatus();
  status.value = [...status.value, ...data];
};

const loadData = async () => {
  await loadCouriers();
  await loadStatus();
  search();
};
loadData();

const search = () => {
  const params = {
    startDate: startDate.value === "" ? null : startDate.value,
    endDate: endDate.value === "" ? null : endDate.value,
    courierId:
      selectedCourier.value?.id === 0 ? null : selectedCourier.value?.id,
    statusId: selectedStatus.value?.id === 0 ? null : selectedStatus.value?.id,
  };
  props.loadShipments(params);
};
</script>
<style scoped>
#filter {
  padding-top: 20px;
}
</style>
