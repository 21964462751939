<template>
  <div class="col-12 lg:col-6">
    <div class="card">
      <h5>Ventas y Envíos</h5>
      <Chart type="line" :data="lineData" />
    </div>
  </div>
</template>
<script setup>
import Chart from "primevue/chart";
import { defineProps, computed } from "vue";
import { getDatesBetweenDates } from "@/services/utils";

const props = defineProps({
  shipments: Array,
  filterData: Object,
  movements: Array,
});

const lineData = computed(() => {
  const dates = getDatesBetweenDates(
    props.filterData.startDate,
    props.filterData.endDate
  );
  const labels = dates.map((date) => date.substr(5, 5));
  const salesData = getSalesData(dates);
  const movementsData = getMovementsData(dates);
  const dataSet = [
    {
      label: "Ventas",
      data: salesData,
      fill: false,
      borderColor: "#03A9F4",
      tension: 0.4,
    },
    {
      label: "Envíos",
      data: movementsData,
      fill: false,
      borderColor: "#FF9800",
      tension: 0.4,
    },
  ];
  return {
    labels,
    datasets: dataSet,
  };
});

const getSalesData = (dates) => {
  const salesData = [];
  dates.forEach((date) => {
    const sales = props.shipments.filter((shipment) =>
      shipment.created_at.toString().includes(date)
    );
    salesData.push(sales.length);
  });
  return salesData;
};

const getMovementsData = (dates) => {
  const movementsData = [];
  dates.forEach((date) => {
    const movements = props.movements.filter((movement) =>
      movement.created_at.toString().includes(date)
    );
    movementsData.push(movements.length);
  });
  return movementsData;
};
</script>
