<template>
  <div class="col-12 lg:col-6">
    <div class="card">
      <h5>Envíos por Zona</h5>
      <Chart type="doughnut" :data="pieData" style="width: 50%" />
    </div>
  </div>
</template>
<script setup>
import Chart from "primevue/chart";
import { defineProps, computed } from "vue";

const props = defineProps({
  movements: Array,
});

const getRegionsFromShipments = () => {
  const regions = props.movements.map((movement) => movement.region_code);
  const uniqueRegions = [...new Set(regions)];
  return uniqueRegions;
};

const getShipmentsData = (regions) => {
  const shipmentsData = [];
  regions.forEach((region) => {
    const filtered = props.movements.filter(
      (shipment) => shipment.region_code === region
    );
    shipmentsData.push(filtered.length);
  });
  return shipmentsData;
};

const generateBackGroundColorsFromLabels = (labels) => {
  const colors = [];
  labels.forEach(() => {
    const color = `rgb(${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, ${Math.floor(Math.random() * 255)})`;
    colors.push(color);
  });
  return colors;
};

const pieData = computed(() => {
  const labels = getRegionsFromShipments();
  const data = getShipmentsData(labels);
  const colors = generateBackGroundColorsFromLabels(labels);
  return {
    labels,
    datasets: [
      {
        data,
        backgroundColor: colors,
      },
    ],
  };
});
</script>
