import axios from "./axios-instance";

export const getShipments = (customerId) => {
  return axios.get(`/shipments/${customerId}`);
};

export const getFilteredShipments = (customerId, payload) => {
  return axios.post(`/shipments/get-filtered/${customerId}`, payload);
};

export const updateStatus = (shipmentId, payload) => {
  return axios.put(`/shipments/${shipmentId}`, payload);
};

export const getMovements = (customerId, payload) => {
  return axios.post(`/shipment-movements/${customerId}`, payload);
};

export const updateShipment = (shipment) => {
  console.log(shipment);
  return axios.put(`/shipments/update/${shipment.id}`, shipment);
};

export const createShipments = (payload) => {
  return axios.post(`/shipments/createShipment`, payload);
};

export const getShipmentDetail = (shipmentId) => {
  return axios.get(`/shipments/detail/${shipmentId}`);
};
